import { RequireTranslations } from 'components/i18n/require-translations';

/*
  Use this hoc only for client side rendered pages.
  It ensures that required translations are loaded before render
 */
export const withTranslations =
  <T extends Record<string, any>>(Component: React.ComponentType<T>, ns: string[]) =>
  (props: T) =>
    (
      <RequireTranslations ns={ns}>
        <Component {...props} />
      </RequireTranslations>
    );
