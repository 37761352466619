import { Trans, useTranslation } from 'next-i18next';
import React, { memo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, ButtonV2, InputV2 } from '@bridebook/ui';
import { IconCrossBold } from '@bridebook/ui/src/icons/dynamic';
import Modal from 'components/bbcommon/modal';
import { resetPassword } from 'lib/auth/actions';
import { ICredentialsFields } from 'lib/auth/types';
import { useDispatch } from 'lib/utils';
import componentStyles from './reset-password-popup.style';

interface IProps {
  showModal: boolean;
  onClose: () => void;
}

const ResetPasswordPopup = ({ showModal, onClose }: IProps) => {
  const { t } = useTranslation('signup');
  const dispatch = useDispatch();
  const [resetPasswordData, setResetPasswordData] = useState<Pick<ICredentialsFields, 'email'>>({
    email: '',
  });
  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
    getValues,
  } = useForm<ICredentialsFields>();

  const styles = componentStyles();

  const handleResetPassword = () => {
    dispatch(resetPassword({ email: getValues().email || resetPasswordData.email }));
    onClose();
  };

  return (
    <Modal show={showModal} id={'signup-login-form'} maxWidth={550} onClose={onClose}>
      <Box style={styles.modalContainer}>
        <Box style={styles.closeIcon} onClick={onClose}>
          <IconCrossBold width={16} />
        </Box>
        <Box style={styles.text}>
          <Trans ns="signup" i18nKey="resetPassword.title">
            Reset <br /> password
          </Trans>
        </Box>
        <Box style={styles.subText}>{t('resetPassword.subtext')}</Box>
        <form
          style={styles.formStyles}
          onSubmit={handleSubmit((data) => setResetPasswordData(data))}>
          <Box style={styles.inputContainer}>
            <Controller
              name={'email'}
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t('invalidEmail'),
                },
              }}
              render={({ field, fieldState }) => (
                <InputV2
                  required
                  type="email"
                  label={t('authEmail')}
                  placeholder={t('authPlaceholder')}
                  {...field}
                  error={fieldState.error?.message}
                  autoFocus
                />
              )}
            />
          </Box>
          <Box style={styles.confirmContainer}>
            <ButtonV2 disabled={!isDirty || !isValid} onClick={handleResetPassword} size={'large'}>
              {t('sendResetLink')}
            </ButtonV2>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default memo(ResetPasswordPopup);
