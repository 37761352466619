import { FelaCSS, FelaCSSWithCustomSelectors, colors } from '@bridebook/ui';

interface IStyles {
  searchWrap: FelaCSS;
  listItem: (selected: boolean) => FelaCSSWithCustomSelectors;
  flagImg: FelaCSS;
  icon: FelaCSS;
}

const styles = (): IStyles => ({
  searchWrap: {
    background: colors.white,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    paddingHorizontal: 4,
    marginTop: 2,
    paddingTop: 2,
    paddingBottom: 2,
  },

  listItem: (selected) => ({
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
    gap: 10,
    color: colors.space75,
    borderBottom: `1px solid ${colors.space08}`,
    fontDefault: 16,
    paddingHorizontal: 4,
    cursor: 'pointer',

    ...(selected
      ? {
          backgroundColor: colors.indigoCrush10,
          fontDefaultSemiBold: 16,
        }
      : {
          hover: {
            backgroundColor: colors.indigoCrush04,
          },
        }),

    '&:last-child': {
      border: 'none',
    },
  }),

  flagImg: {
    width: 25,
  },

  icon: {
    paddingHorizontal: 4,
  },
});

export default styles;
