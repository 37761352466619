import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconChevronRight = ({ id, color, width = 7, style, ...restProps }: IProps) => {
  const viewBoxWidth = 7;
  const viewBoxHeight = 12;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        d="M6.8 5.6L1.5.3C1.3.1 1.1 0 .9 0 .6 0 .4.1.2.3c-.3.3-.3.9 0 1.2l4.7 4.7-4.7 4.7c-.3.3-.3.9 0 1.2.2.2.4.3.6.3.2 0 .4-.1.6-.2l5.3-5.3c.4-.5.4-1 .1-1.3z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconChevronRight;
