import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconApple = ({ id, color, width = 43, style, ...restProps }: IProps) => {
  const viewBoxWidth = 43;
  const viewBoxHeight = 50;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M42.823 36.413c.02.008.037.02.068.038-.146.398-.29.794-.436 1.187-.979 2.618-2.359 5.013-4.047 7.237-.753.992-1.513 1.977-2.418 2.839-.768.731-1.606 1.36-2.598 1.766-1.118.459-2.268.564-3.455.352-1.079-.193-2.104-.552-3.116-.958-1.056-.424-2.145-.73-3.28-.86a9.76 9.76 0 0 0-2.555.056c-1.254.184-2.437.602-3.609 1.066a14.09 14.09 0 0 1-2.645.77c-1.356.247-2.618.004-3.802-.681-1.04-.601-1.922-1.395-2.717-2.282C5.176 43.551 2.89 39.722 1.49 35.38.967 33.76.55 32.117.295 30.434c-.265-1.761-.364-3.532-.243-5.31.144-2.117.595-4.16 1.515-6.084.864-1.81 2.02-3.403 3.576-4.688 1.686-1.392 3.593-2.33 5.75-2.742.833-.16 1.673-.252 2.522-.22.962.038 1.898.244 2.81.535 1.006.323 1.998.69 2.999 1.031.723.247 1.447.489 2.2.639.392.078.765.012 1.129-.107a132.97 132.97 0 0 0 3.249-1.084c1.226-.432 2.462-.819 3.75-1.02a10.447 10.447 0 0 1 2.808-.063c1.196.135 2.37.37 3.509.77 2.281.804 4.147 2.166 5.566 4.127l.083.117c-.298.198-.59.384-.875.582-1.245.868-2.323 1.902-3.157 3.177a9.829 9.829 0 0 0-1.498 3.91c-.284 1.823-.19 3.628.349 5.4.539 1.772 1.517 3.274 2.846 4.56 1.068 1.033 2.269 1.866 3.64 2.449zM21.51 11.237c-.02-.258-.046-.483-.054-.709a7.354 7.354 0 0 1 .49-2.906C23.13 4.552 25.348 2.3 28.69.946c.918-.372 1.867-.665 2.864-.818.353-.054.71-.084 1.083-.128.017.204.036.385.044.566.107 2.425-.778 4.561-2.414 6.473-1.048 1.224-2.28 2.275-3.772 3.071-1.084.579-2.248.964-3.511 1.077-.48.043-.967.034-1.473.05z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconApple;
