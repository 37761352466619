import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  inputContainer: FelaCSS;
  formContainer: FelaCSS;
  resetPasswordContainer: FelaCSS;
  resetPasswordPopup: FelaCSS;
  resetPassword: FelaCSS;
  submitButton: FelaCSS;
  passwordWrapper(isValidEmail: boolean): FelaCSS;
}

interface IStylesProps {
  isDirty: boolean;
  isValid: boolean;
}

const styles = ({ isDirty, isValid }: IStylesProps): IStyles => ({
  inputContainer: {
    gap: 12,
  },
  formContainer: {
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  submitButton: {
    backgroundColor: !isDirty || !isValid ? colors.space40 : colors.indigoCrush,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 8,
    paddingRight: 8,
    color: colors.white,
    border: 'none',
    borderRadius: '54px',
    fontDefault: 16,
  },
  resetPasswordContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  resetPassword: {
    fontDefault: 15,
    color: colors.indigoCrush,
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
    },
  },
  resetPasswordPopup: {
    position: 'absolute',
  },
  passwordWrapper: (isValidEmail) => ({
    maxHeight: isValidEmail ? '200px' : '0px',
    opacity: isValidEmail ? 1 : 0,
    overflow: 'hidden',
    transition: 'max-height 0.5s ease, opacity 0.5s ease',
  }),
});

export default styles;
