import { BasicStore } from 'app-basic/lib/types';
import { createSelector } from 'reselect';

const getAppState = (state: BasicStore) => state.app;
const getDevice = createSelector(getAppState, ({ device }) => device);

export const getPathname = createSelector(getAppState, ({ pathname }) => pathname);
export const getCanonicalLink = createSelector(getAppState, ({ canonicalLink }) => canonicalLink);
export const getAlternateLinks = createSelector(
  getAppState,
  ({ alternateLinks }) => alternateLinks,
);
export const getPreviousPath = createSelector(getAppState, ({ previousPath }) => previousPath);

export const getHost = createSelector(getDevice, ({ host }) => host);
export const getIsMobile = createSelector(getDevice, ({ isMobile }) => isMobile);
export const getIsTablet = createSelector(getDevice, ({ isTablet }) => isTablet);
export const getIsDesktop = createSelector(getDevice, ({ isDesktop }) => isDesktop);
export const getIsCordova = createSelector(getDevice, ({ isCordova }) => isCordova);
export const getIsMainLiveCDN = createSelector(getDevice, ({ isMainLiveCDN }) => isMainLiveCDN);
