import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { memo, useCallback } from 'react';
import { Box, ButtonV2, Well } from '@bridebook/ui';
import AuthButtons from 'components/auth/signup-container/components/auth-buttons/auth-buttons';
import SignupLoginForm from 'components/auth/signup-container/components/signup-login-form/signup-login-form';
import Title from 'components/auth/signup-container/components/title/title';
import { isCollaboratorInvite } from 'lib/auth/selectors';
import { UrlHelper } from 'lib/url-helper';
import { useSelector } from 'lib/utils';
import styles from './login-component.style';

interface IProps {
  showCollaborationAnimationVisible?(show: boolean): void;
}

const LogInComponent = ({ showCollaborationAnimationVisible }: IProps) => {
  const { t } = useTranslation('signup');
  const isCollaboratorsInvite = useSelector(isCollaboratorInvite);
  const router = useRouter();
  const {
    query: { next },
  } = router;

  const toggleSignupMode = useCallback(() => {
    const nextParam = typeof next === 'string' ? `?next=${encodeURIComponent(next)}` : '';
    const navigateUrl = `${UrlHelper.signup}${nextParam}`;
    router.push(navigateUrl);
  }, [next, router]);

  return (
    <>
      <Title />
      <SignupLoginForm showCollaborationAnimationVisible={showCollaborationAnimationVisible} />
      <Box style={styles.loginMethodsDividerWrapper}>
        <hr style={styles.loginMethodsDividerLineBefore} />
        {t('loginMethod.or')}
        <hr style={styles.loginMethodsDividerLineAfter} />
      </Box>

      <AuthButtons showCollaborationAnimationVisible={showCollaborationAnimationVisible} />
      {isCollaboratorsInvite && (
        <Well slim theme="peachRose">
          {t('collaborator.login.warning')}
        </Well>
      )}
      <ButtonV2 variant="link" onClick={toggleSignupMode}>
        {t('dontHaveAnAccount')} {t('signUp')}
      </ButtonV2>
    </>
  );
};

export default memo(LogInComponent);
