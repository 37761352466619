import { FelaCSS, colors, spacing } from '@bridebook/ui';

interface IStyles {
  otherMethod: FelaCSS;
  loginMethodsDividerWrapper: FelaCSS;
  loginMethodsDividerLineAfter: FelaCSS;
  loginMethodsDividerLineBefore: FelaCSS;
}

const styles: IStyles = {
  loginMethodsDividerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    color: colors.space75,
    gap: spacing[5],
    marginInline: spacing[4],
  },
  loginMethodsDividerLineBefore: {
    content: '',
    flexGrow: 1,
    background: colors.space40,
    height: '1px',
  },
  loginMethodsDividerLineAfter: {
    content: '',
    flexGrow: 1,
    background: colors.space40,
    height: '1px',
  },
  otherMethod: {
    alignSelf: 'center',
    width: 'fit-content',
    fontDefault: 16,
    marginTop: 2,
    marginBottom: 6,
  },
};

export default styles;
