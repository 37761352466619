import React from 'react';
import { FelaCSS } from '@bridebook/ui/src/components/fela/flowtypes';
import IconWrap from '@bridebook/ui/src/components/svg/icon-wrap';
import { IStylingProps } from '@bridebook/ui/src/themes/types';
import { TColor } from '@bridebook/ui/src/types';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconMail = ({ id, color, width = 20, style, ...restProps }: IProps) => {
  const viewBoxWidth = 20;
  const viewBoxHeight = 16;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}
      fill="none">
      <path
        fill={iconColor}
        d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 7L2 2H18ZM18 14H2V4L10 9L18 4V14Z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconMail;
