import { useRouter } from 'next/router';

export const useSignUpHelpers = () => {
  const router = useRouter();
  const {
    query: { mode },
  } = router;

  const isSignUp = mode !== 'login';

  return {
    isSignUp,
  };
};
