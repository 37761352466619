import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toggleSnackbar } from 'lib/bbcommon/actions';
import { ICollaboratorInviteType } from 'lib/types';
import { addCollaboratorInvite } from '../actions';

export const useSaveCollaboratorInvite = (serverCollaboratorInvite?: ICollaboratorInviteType) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (serverCollaboratorInvite) {
      if (serverCollaboratorInvite.inviteError) {
        dispatch(
          toggleSnackbar('alert', serverCollaboratorInvite.inviteError, undefined, {
            timeoutMs: 10_000,
          }),
        );
      } else {
        dispatch(addCollaboratorInvite(serverCollaboratorInvite));
      }
    }
  }, [dispatch, serverCollaboratorInvite]);
};
