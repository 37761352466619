import React, { forwardRef, useCallback, useRef, useState } from 'react';
import { Input, InputProps } from '../../../components/bbcommon/input/input';
import { Box } from '../../../fela-components';
import { colors } from '../../../fela-themes';
import { IconEyeCross, IconEyeView } from '../../../icons';
import { IColorTypes } from '../../../types';
import componentStyles from './input-password.style';

const InputPasswordComponent = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [iconColor, setIconColor] = useState<keyof IColorTypes>(
    colors.space40 as keyof IColorTypes,
  );

  // Determine if the component is controlled or uncontrolled based on the presence of the ref
  const isControlled = ref !== null && ref !== undefined;

  const togglePassword = useCallback(() => {
    setPasswordHidden((prev) => !prev);
  }, []);

  const combinedRef = (instance: HTMLInputElement) => {
    inputRef.current = instance;
    if (typeof ref === 'function') {
      ref(instance);
    } else if (ref) {
      ref.current = instance;
    }
  };

  const styles = componentStyles();

  return (
    <Input
      ref={isControlled ? ref : combinedRef}
      {...props}
      type={passwordHidden ? 'password' : 'text'}
      afterInput={
        <Box
          style={styles.passwordIconContainer}
          onClick={togglePassword}
          onMouseEnter={() => setIconColor('indigoCrush40')}
          onMouseLeave={() => setIconColor('space40')}>
          {passwordHidden ? (
            <IconEyeCross color={iconColor} width={22} />
          ) : (
            <IconEyeView color={iconColor} width={22} />
          )}
        </Box>
      }
    />
  );
});

InputPasswordComponent.displayName = 'InputPasswordComponent';
export const InputPassword = React.memo(InputPasswordComponent);
