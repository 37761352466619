import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  width: number;
  height: number;
}

const IconGoogleColor = ({ id, width = 49, style, ...restProps }: IProps) => {
  const viewBoxWidth = 49;
  const viewBoxHeight = 49;

  const icon = ({ width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FEFEFE"
          d="M16.577 1.594C10.573 3.628 5.46 8.072 2.625 13.69a24.073 24.073 0 0 0-2.14 6.126c-1.089 5.294-.33 10.938 2.137 15.76a24.607 24.607 0 0 0 6.692 8.138 24.608 24.608 0 0 0 8.949 4.465c4.102 1.09 8.47 1.065 12.6.134 3.732-.85 7.263-2.615 10.083-5.19 2.98-2.711 5.106-6.282 6.231-10.12 1.228-4.185 1.397-8.653.628-12.938-7.726-.004-15.451-.002-23.177-.001.001 3.171-.004 6.342.002 9.512 4.474-.001 8.948-.002 13.421 0-.518 3.039-2.343 5.817-4.93 7.528-1.627 1.082-3.497 1.785-5.423 2.12-1.94.327-3.943.369-5.878-.02a14.545 14.545 0 0 1-5.483-2.342 15.16 15.16 0 0 1-5.645-7.484c-1.05-3.05-1.057-6.444.005-9.491a15.1 15.1 0 0 1 3.575-5.746c1.987-2.013 4.571-3.453 7.355-4.043a14.735 14.735 0 0 1 7.23.291 13.38 13.38 0 0 1 5.31 3.087c1.515-1.49 3.022-2.988 4.533-4.48.792-.804 1.625-1.572 2.393-2.397-2.295-2.102-4.986-3.792-7.925-4.86-5.293-1.928-11.262-1.969-16.59-.144"
        />
        <path
          fill="#E33729"
          d="M16.577 1.594c5.329-1.825 11.298-1.784 16.59.144 2.94 1.068 5.63 2.758 7.926 4.86-.768.825-1.6 1.593-2.393 2.397-1.511 1.492-3.018 2.99-4.532 4.48a13.38 13.38 0 0 0-5.31-3.087 14.735 14.735 0 0 0-7.231-.29c-2.784.59-5.368 2.029-7.355 4.042a15.1 15.1 0 0 0-3.575 5.746c-2.69-2.066-5.38-4.132-8.072-6.197C5.46 8.072 10.573 3.629 16.577 1.594"
        />
        <path
          fill="#F8BD00"
          d="M.485 19.815a24.073 24.073 0 0 1 2.14-6.126c2.692 2.065 5.382 4.131 8.072 6.197-1.062 3.047-1.054 6.44-.005 9.49-2.69 2.067-5.377 4.135-8.07 6.198C.155 30.753-.604 25.108.485 19.814"
        />
        <path
          fill="#597DBD"
          d="M24.628 20.063c7.726-.001 15.451-.003 23.177.001.77 4.285.6 8.753-.628 12.939-1.125 3.837-3.25 7.408-6.231 10.118-2.605-2.01-5.22-4.007-7.826-6.018 2.588-1.71 4.413-4.49 4.931-7.528-4.473-.002-8.947 0-13.421 0-.006-3.17 0-6.34-.002-9.512"
        />
        <path
          fill="#33A044"
          d="M2.622 35.574c2.693-2.063 5.38-4.131 8.07-6.197a15.16 15.16 0 0 0 5.645 7.484 14.544 14.544 0 0 0 5.483 2.343c1.935.388 3.938.346 5.878.018 1.927-.334 3.796-1.037 5.422-2.119 2.605 2.01 5.221 4.009 7.826 6.018-2.82 2.576-6.351 4.341-10.083 5.191-4.13.93-8.497.955-12.6-.134a24.612 24.612 0 0 1-8.95-4.465 24.612 24.612 0 0 1-6.69-8.139"
        />
      </g>
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconGoogleColor;
