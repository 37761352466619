import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  topContainer: FelaCSS;
  title: FelaCSS;
  closeButton: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    zIndex: 9999999,
    backgroundColor: colors.white,
    height: '100vh',
    width: '100%',
    desktopDesign: {
      alignSelf: 'center',
      maxHeight: '60vh',
      width: '40vw',
      borderRadius: 16,
      overflow: 'hidden',
      justifySelf: 'center',
    },
  },
  topContainer: {
    position: 'sticky',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.space15}`,
    backgroundColor: 'white',
    paddingTop: 'calc(20px + env(safe-area-inset-top))',
    paddingHorizontal: 4,
    paddingBottom: 4,
  },
  title: {
    fontDefault: 16,
    width: '100%',
    textAlign: 'center',
  },
  closeButton: {
    backgroundColor: colors.space04,
    width: 32,
    height: 32,
    borderRadius: '50%',
    justifyContent: 'center',
    position: 'absolute',
    right: 16,
  },
});

export default styles;
