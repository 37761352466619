import React, { PropsWithChildren } from 'react';
import { Box } from '@bridebook/ui';
import { IconCrossBold } from '@bridebook/ui/src/icons';
import componentStyles from './mobile-slide-content-wrapper.style';

interface IProps {
  onClose: () => void;
  title?: string;
}

export const MobileSlideContentWrapper = ({
  onClose,
  title,
  children,
}: PropsWithChildren<IProps>) => {
  const styles = componentStyles();
  return (
    <Box style={styles.wrapper}>
      <Box style={styles.topContainer}>
        {title && <Box style={styles.title}>{title}</Box>}
        <Box style={styles.closeButton} onClick={onClose}>
          <IconCrossBold width={10} color="space" />
        </Box>
      </Box>
      {children}
    </Box>
  );
};
