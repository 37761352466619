import { FelaCSS } from '@bridebook/ui';

interface IStyles {
  socialAuthButton: FelaCSS;
  socialAuthButtonContainer: FelaCSS;
  socialAuthButtonWrapper: FelaCSS;
  socialAuthFlagWrapper: FelaCSS;
}

const styles: IStyles = {
  socialAuthButton: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 1fr',
  },
  socialAuthButtonContainer: {
    width: 'inherit',
    paddingHorizontal: 0,
  },
  socialAuthButtonWrapper: {
    paddingHorizontal: 0,
  },
  socialAuthFlagWrapper: {
    width: '52px',
    justifyContent: 'center',
  },
};

export default styles;
