import { useTranslation } from 'next-i18next';
import React, { ReactNode } from 'react';
import { useCanRender } from 'lib/app/app-rendering-context';

interface RequireTranslationsProps {
  ns: string[];
  children: ReactNode;
}

/*
  Use this component only for client side rendered pages.
  It ensures that required translations are loaded before render
 */
export const RequireTranslations: React.FC<RequireTranslationsProps> = ({ ns, children }) => {
  const { ready } = useTranslation(ns);
  const canRender = useCanRender();
  return canRender && ready ? <>{children}</> : null;
};
