//   const collaboratorInvite = useAppSelector((state) => state.auth.collaboratorInvite);
import { createSelector } from 'reselect';
import type { IApplicationState } from 'lib/types';

const _auth = (state: IApplicationState) => state.auth;

export const _getCollaboratorInvite = createSelector(
  _auth,
  ({ collaboratorInvite }) => collaboratorInvite,
);

export const getCollaboratorInviteSupplierPhoto = createSelector(
  _auth,
  ({ collaboratorInvite }) => collaboratorInvite.inviteSupplierPhoto,
);

export const getAuthForm = createSelector(_auth, ({ form }) => form);

export const getInviteSupplier = createSelector(
  _getCollaboratorInvite,
  ({ inviteSupplier }) => inviteSupplier,
);

export const getInviteSupplierWedding = createSelector(
  _getCollaboratorInvite,
  ({ inviteSupplierWedding }) => inviteSupplierWedding,
);

export const getAuthFormError = createSelector(getAuthForm, ({ error }) => error);

export const getAuthFormDisabled = createSelector(getAuthForm, ({ disabled }) => disabled);

export const getIsAdmin = createSelector(_auth, ({ isAdmin }) => isAdmin);
export const getIsAdminACLLoaded = createSelector(_auth, ({ adminACLLoaded }) => adminACLLoaded);
