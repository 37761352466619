import { CustomNameValueEvent } from '@bridebook/ui';
import { AuthActionTypes } from 'lib/auth/action-types';

const FORM_FIELD_MAX_LENGTH = 100;

export const onAuthFormFieldChange = (e: CustomNameValueEvent) => {
  let value = (e.target as HTMLInputElement).value;
  const name = (e.target as HTMLInputElement).name;

  value = value.slice(0, FORM_FIELD_MAX_LENGTH);
  return {
    type: AuthActionTypes.ON_AUTH_FORM_FIELD_CHANGE,
    payload: { name, value },
  };
};
