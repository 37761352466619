import { MutableRefObject, ReactNode, useEffect } from 'react';
import { useFela } from 'react-fela';
import { FelaCSS } from '@bridebook/ui';

export interface AnimationTiming {
  // The target div to animate
  target?: MutableRefObject<ReactNode | HTMLDivElement | undefined>;
  // The delay time in seconds before starting the animation
  delay?: number;
  // The duration time in seconds of the animation
  duration: number;
  // Style object to apply to the target div (presumably with animations)
  style?: FelaCSS;
  // Whether to display the target div on animation start
  showOnStart?: boolean;
  // Whether to hide the target div on animation finish
  hideOnFinish?: boolean;
  // A callback function to run on animation start
  onStart?: () => void;
  // A callback function to run on animation finish
  onFinish?: () => void;
}

/**
 * A hook for running CSS animations one by one as a sequence
 * Can also be used to show and hide React components, eg. with Lottie animations
 */
export const useAnimateSequence = (animations: AnimationTiming[]) => {
  const { css } = useFela();
  const timeouts: NodeJS.Timeout[] = [];

  // Define a function for animating a single target div
  const animateDiv = ({
    duration,
    hideOnFinish,
    onStart,
    onFinish,
    showOnStart,
    style,
    target,
  }: AnimationTiming) => {
    // Run onStart callback before the animation starts
    if (onStart) onStart();
    // Run onFinish callback after the animation finishes
    if (onFinish) timeouts.push(setTimeout(onFinish, duration * 1000));

    const current = target?.current as HTMLElement;
    if (!current) return;

    if (showOnStart) current.style.display = 'flex';

    // Apply the styles to the target div
    if (style) current.classList.add(...css(style).split(' '));

    // Hide the target div after the animation finishes
    if (hideOnFinish)
      timeouts.push(setTimeout(() => (current.style.display = 'none'), duration * 1000));
  };

  useEffect(() => {
    let nextDelay = 0;

    // Create timeouts for each animation from the set
    // which will apply specific styles to the targets
    animations.forEach((animation) => {
      const currentDelay = nextDelay + (animation.delay || 0);
      nextDelay = currentDelay + animation.duration;
      timeouts.push(setTimeout(() => animateDiv(animation), currentDelay * 1000));
    });

    // Clear timeouts on unmount
    return () => {
      timeouts.forEach((id) => clearTimeout(id));
    };
  }, []);
};
