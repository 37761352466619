import { FelaCSS, alpha, colors } from '@bridebook/ui';

interface IStyles {
  headingNames: FelaCSS;
  names: FelaCSS;
  wrapper: FelaCSS;
  subTitle: FelaCSS;
}

const styles: IStyles = {
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: colors.white,
    zIndex: 10,
    boxShadow: `0 0 60px ${alpha(colors.black, 10)}`,
    overflow: 'hidden',
    justifyContent: 'center',
    height: '100%',
    desktopDesign: {
      borderRadius: 16,
    },
  },
  headingNames: {
    fontDefault: 20,
    width: '100%',
  },

  names: {
    fontPlayfair: 35,
    lineHeight: '40px',
    marginVertical: 4,
    textAlign: 'center',
  },
  subTitle: {
    textAlign: 'center',
  },
};

export default styles;
