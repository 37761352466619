import { getAuthFormDisabled } from 'cms-suppliers/lib/auth/selectors';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { memo, useCallback, useState } from 'react';
import { Box, ButtonV2, Well } from '@bridebook/ui';
import { IconMail } from '@bridebook/ui/src/icons';
import AuthCountrySelect from 'components/auth/country-select/auth-country-select';
import AuthButton from 'components/auth/signup-container/components/auth-buttons/auth-button/auth-button';
import AuthButtons from 'components/auth/signup-container/components/auth-buttons/auth-buttons';
import SignupLoginForm from 'components/auth/signup-container/components/signup-login-form/signup-login-form';
import Title from 'components/auth/signup-container/components/title/title';
import { isCollaboratorInvite, isCountrySelected } from 'lib/auth/selectors';
import { UrlHelper } from 'lib/url-helper';
import { useSelector } from 'lib/utils';
import styles from './signup-component.style';

interface IProps {
  showCollaborationAnimationVisible?(show: boolean): void;
}

const SignupComponent = ({ showCollaborationAnimationVisible }: IProps) => {
  const { t } = useTranslation('signup');
  const isCollaboratorsInvite = useSelector(isCollaboratorInvite);
  const countrySelected = useSelector(isCountrySelected);
  const authFormDisabled = useSelector(getAuthFormDisabled);
  const [showSocialAuthButtons, setShowSocialAuthButtons] = useState(true);
  const router = useRouter();
  const {
    query: { next },
  } = router;

  const toggleSocialAuthButtonsState = useCallback(() => {
    setShowSocialAuthButtons((prevState) => !prevState);
  }, []);

  const toggleSignupMode = useCallback(() => {
    const nextParam = typeof next === 'string' ? `&next=${encodeURIComponent(next)}` : '';

    const navigateUrl = `${UrlHelper.login}${nextParam}`;

    router.push(navigateUrl);
  }, [next, router]);

  return (
    <>
      {!isCollaboratorsInvite && (
        <Box style={styles.countrySelector}>
          <AuthCountrySelect />
        </Box>
      )}
      <Title socialAuthVisible={showSocialAuthButtons} />
      <Box gap={3}>
        {!isCollaboratorsInvite && <Box style={styles.startPlanning}>{t('letsStartPlanning')}</Box>}
        {showSocialAuthButtons ? (
          <>
            <AuthButton
              onClick={toggleSocialAuthButtonsState}
              text={t('signupWithEmail')}
              icon={<IconMail color="white" width={18} />}
              dataTest="continue-with-email"
              disabled={!countrySelected || authFormDisabled}
              variant="primary"
            />
            <AuthButtons showCollaborationAnimationVisible={showCollaborationAnimationVisible} />
          </>
        ) : (
          <>
            <SignupLoginForm
              showCollaborationAnimationVisible={showCollaborationAnimationVisible}
            />
            <ButtonV2 onClick={toggleSocialAuthButtonsState} variant="link">
              {t('otherMethod')}
            </ButtonV2>
          </>
        )}
      </Box>
      {isCollaboratorsInvite && (
        <Well slim theme="peachRose">
          {showSocialAuthButtons
            ? t('collaborator.welcome.warning')
            : t('collaborator.signup.warning')}
        </Well>
      )}
      <ButtonV2 variant="link" onClick={toggleSignupMode}>
        {t('alreadyHaveAnAccount')} {t('logIn')}
      </ButtonV2>
    </>
  );
};

export default memo(SignupComponent);
