import React, { useCallback, useMemo, useState } from 'react';
import gazetteer, { CountryCodes, Market } from '@bridebook/toolbox/src/gazetteer';
import { ButtonV2, ICountryOption } from '@bridebook/ui';
import { IconChevron } from '@bridebook/ui/src/icons';
import { CountrySelectModalContent } from 'app-shared/components/contry-selector/country-select-modal-content';
import Modal from 'components/bbcommon/modal';

interface IProps {
  /**
   * Modal title.
   */
  modalHeader: string;

  /**
   * Placeholder for the filter input.
   */
  filterPlaceholder?: string;

  /**
   * Filter functions allowing to filter the countries list.
   */
  countriesFilter?(value: Market): boolean;

  /**
   * Callback to be called when a country is selected.
   */
  onClick(value: ICountryOption): void;

  /**
   * Currently selected country.
   */
  selectedCountry: CountryCodes;
}

const FlagCountrySelector = ({
  onClick,
  selectedCountry,
  modalHeader,
  filterPlaceholder,
  countriesFilter,
}: IProps) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = useCallback(() => setShowModal((prev) => !prev), []);

  // Default options mapper
  const optionsMapper = useCallback(
    (market: Market): ICountryOption => ({
      value: market.country,
      label: market.getCountryName(),
      flagImg: market.getCountryFlagImageURL(),
      market,
    }),
    [],
  );

  // Map markets to dropdown options
  const options = useMemo(
    () =>
      gazetteer
        .getMarkets()
        .filter((market) => (countriesFilter ? countriesFilter(market) : true))
        .map(optionsMapper)
        .sort((a, b) => a.market.getCountryName().localeCompare(b.market.getCountryName())),
    [countriesFilter, optionsMapper],
  ) as ICountryOption[];

  return (
    <>
      <ButtonV2 size="small" onClick={toggleModal} variant="secondary">
        <>
          <img
            src={optionsMapper(gazetteer.getMarketByCountry(selectedCountry)).flagImg}
            width={25}
            loading="lazy"
          />
          <IconChevron width={10} color="space" />
        </>
      </ButtonV2>
      {showModal && (
        <Modal id="country-select-modal" show={showModal} onClose={toggleModal}>
          <CountrySelectModalContent
            onClose={toggleModal}
            options={options}
            onClick={onClick}
            modalHeader={modalHeader}
            showFilter
            filterPlaceholder={filterPlaceholder}
            selected={selectedCountry}
          />
        </Modal>
      )}
    </>
  );
};
export default FlagCountrySelector;
