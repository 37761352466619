import { FelaCSS } from '@bridebook/ui';

interface IStyles {
  startPlanning: FelaCSS;
  countrySelector: FelaCSS;
}

const styles: IStyles = {
  startPlanning: {
    fontDefaultSemiBold: 14,
  },
  countrySelector: {
    justifySelf: 'flex-end',
  },
};

export default styles;
