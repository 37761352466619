import { User as FirebaseUser } from 'firebase/auth';
import { AuthActionTypes } from 'lib/auth/action-types';
import { AuthBridebookError, AuthProviders } from 'lib/auth/types';
import { setSettingsNotification } from 'lib/settings/actions';
import { ICollaboratorInviteType, IDeps } from 'lib/types';
import { settingsToggleLinkEmailForm } from 'lib/ui/actions';
import { saveUserProvider } from 'lib/users/actions';

export const linkSocialAccount = ({ providerId }: { providerId: AuthProviders }) => ({
  type: AuthActionTypes.LINK_SOCIAL_ACCOUNT,
  payload: {
    providerId,
  },
});

export const linkEmailAccount = ({
  credentials,
}: {
  credentials: { email: string; password: string };
}) => ({
  type: AuthActionTypes.LINK_EMAIL_ACCOUNT,
  payload: {
    credentials,
  },
});

export const linkAccountsError =
  ({ providerId, error }: { providerId: AuthProviders; error: AuthBridebookError }) =>
  ({ dispatch, getState }: IDeps) => {
    const {
      form: { fields: credentials },
    } = getState().auth;

    const isSocialAuth = providerId !== AuthProviders.PASSWORD;

    error.provider = providerId;
    dispatch(setSettingsNotification('LINK_ACCOUNTS_ERROR', error));
    dispatch({
      type: 'USER_UPDATED_AUTH_DETAILS_ERROR_ANALYTICS',
      payload: {
        provider: providerId,
        fields: isSocialAuth ? null : credentials,
        error,
      },
    });

    return {
      type: 'LINK_ACCOUNTS_ERROR',
      payload: error,
    };
  };

export const linkAccountsSuccess =
  ({ user: firebaseUser, providerId }: { user?: FirebaseUser | null; providerId: AuthProviders }) =>
  ({ dispatch, getState }: IDeps) => {
    if (firebaseUser) {
      const {
        form: { fields: credentials },
      } = getState().auth;
      const isSocialAuth = providerId !== AuthProviders.PASSWORD;

      const passwordProvider = firebaseUser.providerData.find((provider) =>
        Boolean(provider && provider.providerId === providerId),
      );

      if (passwordProvider) {
        dispatch(saveUserProvider(passwordProvider));

        dispatch(settingsToggleLinkEmailForm(false));
        // @ts-ignore FIXME
        dispatch(setSettingsNotification('LINK_ACCOUNTS_SUCCESS', passwordProvider));
        dispatch({
          type: 'USER_UPDATED_AUTH_DETAILS_SUCCESS_ANALYTICS',
          payload: {
            provider: providerId,
            fields: isSocialAuth ? null : credentials,
          },
        });
      }
    }

    return {
      type: AuthActionTypes.LINK_ACCOUNTS_SUCCESS,
      payload: { user: firebaseUser },
    };
  };

export const addCollaboratorInvite = (collaboratorInvite: ICollaboratorInviteType) => ({
  type: AuthActionTypes.ADD_COLLABORATOR_INVITE,
  payload: collaboratorInvite,
});
