import { FelaCSS } from '../../../components/fela/flowtypes';
import colorParse from '../../../fela-utils/color-parse';
import { colors } from '../../../themes/variables';
import { ISharedTypes as IStyleProps } from './border-wrap';

interface IStyles {
  wrapper: FelaCSS;
  container: FelaCSS & {
    '> div': FelaCSS;
  };
}

const styles = ({ color, fitContainer, slim, borderless }: IStyleProps): IStyles => {
  const borderColor = color ? colorParse(color) : colors.space;
  const radius = '12px';

  return {
    wrapper: {
      paddingLeft: slim || borderless ? undefined : '3px',
      flexGrow: fitContainer ? 1 : undefined,
    },

    container: {
      flexGrow: 1,
      borderWidth: borderless ? '0' : '1px',
      borderStyle: 'solid',
      borderColor,
      borderRadius: radius,
      boxShadow: slim || borderless ? undefined : `-3px 0 0 0 ${borderColor}`,

      '> div': {
        borderRadius: radius,
      },
    },
  };
};

export default styles;
