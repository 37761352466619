import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import gazetteer, { CountryCodes, Market } from '@bridebook/toolbox/src/gazetteer';
import { countriesExcludedFromSignup } from '@bridebook/toolbox/src/i18n/features';
import { ICountryOption } from '@bridebook/ui';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import FlagCountrySelector from 'components/bbcommon/flag-country-selector/flag-country-selector';
import { getIsCordova } from 'lib/mobile-app/selectors';
import { UrlHelper } from 'lib/url-helper';
import { useSelector } from 'lib/utils';

const AuthCountrySelect = () => {
  const { t } = useTranslation('signup');
  const router = useRouter();
  const market = useMarket();

  const isCordova = useSelector(getIsCordova);

  const countriesFilter = useCallback(
    (market: Market) =>
      isCordova ||
      (market.hasAnyDirectory && !countriesExcludedFromSignup.includes(market.country)),
    [isCordova],
  );

  const changeFormCountryValue = useCallback(
    (option: ICountryOption) => {
      const newMarket = gazetteer.getMarketByCountry(option.value as CountryCodes);
      router.push(`/${newMarket.prefix}${UrlHelper.signup}`, undefined, { locale: false });
    },
    [router],
  );

  return (
    <FlagCountrySelector
      onClick={changeFormCountryValue}
      modalHeader={t('selectOrSearchForCountry')}
      selectedCountry={market.country}
      countriesFilter={countriesFilter}
    />
  );
};

export default AuthCountrySelect;
