import { getAuthFormDisabled } from 'cms-suppliers/lib/auth/selectors';
import { useTranslation } from 'next-i18next';
import React, { memo, useCallback, useMemo } from 'react';
import { IconApple, IconFacebook, IconGoogleColor } from '@bridebook/ui/src/icons';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { useAuthContext } from 'components/auth/auth-context';
import AuthButton from 'components/auth/signup-container/components/auth-buttons/auth-button/auth-button';
import { LoginOrSignupResult } from 'lib/api/authenticate/types';
import { disableAuthForm, toggleSelectedCountryError } from 'lib/auth/actions';
import { useSignUpHelpers } from 'lib/auth/hooks/use-signup-hooks';
import { getEmailExistsWithDifferentProvider, isCountrySelected } from 'lib/auth/selectors';
import { AuthProviders } from 'lib/auth/types';
import { toggleSnackbar } from 'lib/bbcommon/actions';
import { getIsIOS, getIsMacOS } from 'lib/mobile-app/selectors';
import { useDispatch, useSelector } from 'lib/utils';

interface IProps {
  showCollaborationAnimationVisible?: (show: boolean) => void;
}

const AuthButtons = ({ showCollaborationAnimationVisible }: IProps) => {
  const { t } = useTranslation('signup');
  const {
    loginOrSignupWithGoogle,
    loginOrSignupWithApple,
    loginOrSignupWithFacebook,
    redirectAfterLoginOrSignup,
    prepareRedirectAfterLoginOrSignup,
  } = useAuthContext();
  const dispatch = useDispatch();
  const authFormDisabled = useSelector(getAuthFormDisabled);
  const countrySelected = useSelector(isCountrySelected);
  const market = useMarket();
  const emailExistsWithDifferentProvider = useSelector(getEmailExistsWithDifferentProvider);
  const isiOS = useSelector(getIsIOS);
  const isMacOS = useSelector(getIsMacOS);
  const { isSignUp } = useSignUpHelpers();

  const isAppleVisible = isiOS || isMacOS;

  const toggleError = isSignUp && !countrySelected;

  const handleSocialLogin = useCallback(
    async (provider: Exclude<AuthProviders, AuthProviders.PASSWORD>) => {
      if (toggleError) {
        dispatch(toggleSelectedCountryError(true, provider));
        dispatch(toggleSnackbar('alert', t('pleaseSelectCountry')));
      } else {
        dispatch(disableAuthForm);
        let result: LoginOrSignupResult | undefined;
        const loginOrSignupParams = { countryCode: market.country, locale: market.locale };

        switch (provider) {
          case AuthProviders.APPLE:
            result = await loginOrSignupWithApple(loginOrSignupParams);
            break;
          case AuthProviders.GOOGLE:
            result = await loginOrSignupWithGoogle(loginOrSignupParams);
            break;
          case AuthProviders.FACEBOOK:
            result = await loginOrSignupWithFacebook(loginOrSignupParams);
            break;
        }

        if (result && result.status === 'success' && !result.isCollaborator) {
          await redirectAfterLoginOrSignup(result);
        }
        if (result && result.status === 'success' && result.isCollaborator) {
          prepareRedirectAfterLoginOrSignup(result);
          showCollaborationAnimationVisible?.(true);
        }
      }
    },
    [
      dispatch,
      loginOrSignupWithApple,
      loginOrSignupWithFacebook,
      loginOrSignupWithGoogle,
      market.country,
      market.locale,
      prepareRedirectAfterLoginOrSignup,
      redirectAfterLoginOrSignup,
      showCollaborationAnimationVisible,
      t,
      toggleError,
    ],
  );

  const handleAppleLogin = useCallback(
    () => handleSocialLogin(AuthProviders.APPLE),
    [handleSocialLogin],
  );
  const handleGoogleLogin = useCallback(
    () => handleSocialLogin(AuthProviders.GOOGLE),
    [handleSocialLogin],
  );
  const handleFacebookLogin = useCallback(
    () => handleSocialLogin(AuthProviders.FACEBOOK),
    [handleSocialLogin],
  );

  const authButtonsConfig = useMemo(
    () => ({
      ...(isAppleVisible && {
        appleButton: {
          text: isSignUp ? t('signupApple') : t('loginApple'),
          onClick: handleAppleLogin,
          icon: <IconApple width={18} color="black" />,
        },
      }),
      ...(emailExistsWithDifferentProvider !== AuthProviders.GOOGLE && {
        googleButton: {
          text: isSignUp ? t('signupGoogle') : t('loginGoogle'),
          onClick: handleGoogleLogin,
          icon: <IconGoogleColor width={19} />,
        },
      }),
      ...(emailExistsWithDifferentProvider !== AuthProviders.FACEBOOK && {
        facebookButton: {
          text: isSignUp ? t('signupFacebook') : t('loginFacebook'),
          onClick: handleFacebookLogin,
          icon: <IconFacebook color="facebookBlue" width={10} />,
        },
      }),
    }),
    [
      emailExistsWithDifferentProvider,
      handleAppleLogin,
      handleFacebookLogin,
      handleGoogleLogin,
      isAppleVisible,
      isSignUp,
      t,
    ],
  );

  return (
    <>
      {Object.entries(authButtonsConfig).map(([key, button]) => (
        <AuthButton key={key} disabled={authFormDisabled} {...button} />
      ))}
    </>
  );
};

export default memo(AuthButtons);
