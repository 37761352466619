import { colors } from '../../../themes/variables';
import { FelaCSS, FelaCSSWithCustomSelectors } from '../../fela/flowtypes';

interface IStyles {
  wrapper: FelaCSSWithCustomSelectors;
  innerWrapper: FelaCSS;
  error: FelaCSS;
  helper: FelaCSS;
  input: FelaCSS | FelaCSSWithCustomSelectors;
  label: FelaCSS;
  inputWrapper: FelaCSSWithCustomSelectors;
  clearBtn: FelaCSS;
  startIconWrapper: FelaCSS;
  cross: FelaCSS;
  container: FelaCSS;
}

interface IStylesArgs {
  hasError: boolean;
  hasLabel: boolean;
  disabled?: boolean;
  isActive?: boolean;
  wrapperStyle: FelaCSS | FelaCSSWithCustomSelectors;
  containerStyle: FelaCSS | FelaCSSWithCustomSelectors;
  inputStyle: FelaCSS | FelaCSSWithCustomSelectors;
  readOnly?: boolean;
}

const styles = ({
  hasError,
  hasLabel,
  disabled,
  isActive,
  wrapperStyle,
  containerStyle,
  inputStyle,
  readOnly,
}: IStylesArgs): IStyles => ({
  container: containerStyle,
  wrapper: {
    border: hasError ? `1px solid ${colors.alerts}` : `1px solid ${colors.space40}`,
    borderRadius: hasLabel ? 16 : 12,
    ...(!disabled && {
      ':focus-within': { border: !hasError && `1px solid ${colors.indigoCrush}` },
      ':hover': { border: !hasError && `1px solid ${colors.space}` },
      ':focus': { border: `1px solid ${colors.indigoCrush}` },
    }),
    ...(isActive && { border: `1px solid ${colors.indigoCrush}` }),
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    backgroundColor: disabled ? colors.space04 : colors.white,
    ...wrapperStyle,
  },

  innerWrapper: {
    paddingVertical: 2,
    paddingLeft: 4,
    marginRight: 4,
    width: '100%',
  },

  error: {
    paddingTop: 1,
    paddingHorizontal: 4,
    fontDefaultSemiBold: 12,
    color: colors.alerts,
    maxWidth: '100%',
    margin: 0,
  },

  helper: {
    paddingTop: 1,
    paddingHorizontal: 4,
    fontDefaultSemiBold: 12,
    color: colors.space60,
    maxWidth: '100%',
  },

  input: {
    border: 'none',
    fontSize: 16,
    paddingLeft: 0,
    width: '100%',
    background: 'transparent',
    color: disabled ? colors.space40 : colors.space,
    ...inputStyle,

    '::placeholder': {
      color: colors.space40,
    },
    ...(readOnly && { cursor: 'pointer' }),
  },

  label: {
    display: 'inline',
    fontDefaultSemiBold: 12,
    color: disabled ? colors.space75 : colors.space,
  },

  inputWrapper: {
    flexDirection: 'row',
    fontDefault: 16,
    gap: 4,
    alignItems: 'center',

    // Removing input arrows
    '& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
  clearBtn: {
    padding: 4,
    cursor: 'pointer',
  },
  startIconWrapper: {
    paddingRight: 2,
  },
  cross: {
    paddingRight: 4,
    cursor: 'pointer',
  },
});

export default styles;
