import React from 'react';
import { Box, FelaCSS } from '@bridebook/ui';
import mergeStyles from '@bridebook/ui/src/fela-utils/merge-styles';
import componentStyles from './step-header.style';

export const StepHeader = ({
  title,
  subtitle,
  wrapperAlignSelf,
  subtitleStyle,
}: {
  title?: string;
  subtitle?: string;
  wrapperAlignSelf?: FelaCSS['alignSelf'];
  subtitleStyle?: FelaCSS;
}) => {
  const styles = componentStyles(wrapperAlignSelf);

  return (
    <Box style={styles.wrapper}>
      <Box style={styles.title}>{title}</Box>
      <Box style={subtitleStyle ? mergeStyles([styles.subtitle, subtitleStyle]) : styles.subtitle}>
        {subtitle}
      </Box>
    </Box>
  );
};
