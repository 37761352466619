import { useTranslation } from 'next-i18next';
import React, { memo, useMemo } from 'react';
import { Box } from '@bridebook/ui';
import { useSignUpHelpers } from 'lib/auth/hooks/use-signup-hooks';
import {
  getCollaboratorsName,
  isCollaboratorInvite,
  selectCollaboratorMethod,
} from 'lib/auth/selectors';
import { useSelector } from 'lib/utils';
import styles from './title.style';

const Title = ({ socialAuthVisible }: { socialAuthVisible?: boolean }) => {
  const { t } = useTranslation('signup');
  const isCollaboratorsInvite = useSelector(isCollaboratorInvite);
  const collaboratorMethod = useSelector(selectCollaboratorMethod);
  const [partnerName1, partnerName2] = useSelector(getCollaboratorsName);
  const { isSignUp } = useSignUpHelpers();

  const subtitleString = useMemo(() => {
    if (isSignUp) {
      return socialAuthVisible
        ? t('collaborator.welcome', {
            partnerName1,
            partnerName2,
          })
        : t('collaborator.signup', { partnerName1, partnerName2 });
    }
    return t('collaborator.login', { partnerName1 });
  }, [isSignUp, t, partnerName1, socialAuthVisible, partnerName2]);

  if (!isCollaboratorsInvite) {
    return isSignUp ? (
      <Box style={styles.title}>{t('signupTitle')}</Box>
    ) : (
      <Box style={styles.title}>{t('logIn')}</Box>
    );
  }

  return (
    <>
      {!collaboratorMethod && (
        <Box display="contents">
          <Box style={styles.title}>{t('letsPlanTogether')}</Box>
          <Box style={styles.collaboratorSubtitle}>{subtitleString}</Box>
        </Box>
      )}
      {collaboratorMethod === 'askForFeedback' && (
        <Box display="contents">
          <Box style={styles.collaboratorFeedbackTitle}>
            {isSignUp
              ? t('collaborator.feedback.signup.title')
              : t('collaborator.feedback.login.title')}
          </Box>
          <Box style={styles.collaboratorFeedbackSubtitle}>
            {isSignUp
              ? t('collaborator.feedback.signup.subtitle', {
                  partnerName1,
                  partnerName2,
                })
              : t('collaborator.feedback.login.subtitle', {
                  partnerName1,
                  partnerName2,
                })}
          </Box>
        </Box>
      )}
    </>
  );
};

export default memo(Title);
