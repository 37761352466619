import { FelaCSS } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  title: FelaCSS;
  subtitle: FelaCSS;
}

const styles = (alignSelf: FelaCSS['alignSelf'] = 'flex-start'): IStyles => ({
  wrapper: {
    alignSelf,
  },
  title: {
    fontDefault: 20,
    lineHeight: '26px',
  },
  subtitle: {
    fontPlayfair: 40,
    lineHeight: '100%',
  },
});

export default styles;
