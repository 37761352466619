import { useTranslation } from 'next-i18next';
import React, { ReactNode, memo, useCallback, useMemo, useRef } from 'react';
import { Box } from '@bridebook/ui';
import { useAuthContext } from 'components/auth/auth-context';
import { BackgroundStarsAnimation } from 'components/onboarding-new/components/animations/blocks/background-stars-animation';
import {
  fadeInRightAnimation,
  fadeOutAnimation,
} from 'components/onboarding-new/components/animations/helpers/fade-animations';
import {
  AnimationTiming,
  useAnimateSequence,
} from 'components/onboarding-new/components/animations/helpers/use-animate-sequence';
import { getCollaboratorsName } from 'lib/auth/selectors';
import { useSelector } from 'lib/utils';
import styles from './collaboration-flow-end-animation.style';

const CollaborationFlowEndAnimation = () => {
  const { triggerRedirectAfterLoginOrSignup } = useAuthContext();
  const { t } = useTranslation('signup');
  const partners = useSelector(getCollaboratorsName);

  const refNames = useRef<ReactNode>();
  const refSlide = useRef<ReactNode>();

  const onAnimationFinish = useCallback(async () => {
    await triggerRedirectAfterLoginOrSignup();
  }, [triggerRedirectAfterLoginOrSignup]);

  const sequence: AnimationTiming[] = useMemo(
    () => [
      {
        target: refSlide,
        delay: 0.5,
        duration: 1,
        style: fadeInRightAnimation(1),
        showOnStart: true,
        onStart: () => window.scroll(0, 50),
      },
      {
        target: refNames,
        delay: 0.5,
        duration: 0.2,
        style: fadeOutAnimation(0.2),
        onFinish: () => onAnimationFinish(),
      },
    ],
    [onAnimationFinish],
  );

  useAnimateSequence(sequence);

  return (
    <Box style={styles.wrapper} ref={refSlide} data-name="CollaborationFlowEndAnimation">
      <BackgroundStarsAnimation />
      <Box alignItems={'center'}>
        <Box style={styles.headingNames} ref={refNames} id="names">
          <Box style={{ textAlign: 'center' }}>{t('animation.congratulations')}</Box>
          <Box style={styles.subTitle}>{t('animation.your.joined.wedding')}</Box>
          <Box style={styles.names}>{partners && `${partners[0]} & ${partners[1]}`}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(CollaborationFlowEndAnimation);
