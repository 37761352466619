import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  modalContainer: FelaCSS;
  text: FelaCSS;
  userEmail: FelaCSS;
  confirmContainer: FelaCSS;
  closeIcon: FelaCSS;
  subText: FelaCSS;
  formStyles: FelaCSS;
  inputContainer: FelaCSS;
}

const styles = (): IStyles => ({
  modalContainer: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    marginHorizontal: 2,
    paddingHorizontal: 2,
    paddingVertical: 8,
    borderRadius: 24,
    desktopDesign: {
      paddingHorizontal: 6,
      paddingVertical: 4,
    },
  },
  text: {
    lineHeight: '48px',
    textAlign: 'center',
    fontPlayfair: 40,
    paddingHorizontal: 4,
    paddingVertical: 5,
  },
  subText: {
    paddingHorizontal: 4,
    marginVertical: 4,
    paddingBottom: 2,
    fontDefault: 16,
  },
  userEmail: {
    textAlign: 'center',
    fontDefault: 16,
    paddingBottom: 4,
  },
  confirmContainer: {
    width: '100%',
    padding: 2,
    paddingBottom: 6,
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    border: `1px solid ${colors.space08}`,
    padding: 3,
    borderRadius: 100,
    cursor: 'pointer',
    zIndex: 99999,
  },
  formStyles: {
    width: '100%',
  },
  inputContainer: {
    width: '100%',
    paddingHorizontal: 2,
    paddingBottom: 2,
  },
});

export default styles;
