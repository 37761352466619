import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconCross = ({ id, color, width = 46, style, ...restProps }: IProps) => {
  const viewBoxWidth = 46;
  const viewBoxHeight = 46;
  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        d="M5.393 45.162a2.8 2.8 0 0 1-3.995 0 2.885 2.885 0 0 1 0-4.045L19.005 23.29.828 4.883a2.885 2.885 0 0 1 0-4.045 2.798 2.798 0 0 1 3.993 0L23 19.245 41.178.838a2.798 2.798 0 0 1 3.994 0 2.885 2.885 0 0 1 0 4.045L26.995 23.29l17.607 17.828a2.885 2.885 0 0 1 0 4.045 2.8 2.8 0 0 1-3.995 0L23 27.334 5.393 45.162z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconCross;
