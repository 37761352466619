import React, { PropsWithChildren } from 'react';
import Box from '../../../components/fela/Box';
import { IStylingProps } from '../../../themes/types';
import componentStyles from './border-wrap.style';

export interface ISharedTypes extends PropsWithChildren {
  color: string;
  fitContainer?: boolean;
  slim?: boolean;
  borderless?: boolean;
}

interface IProps extends ISharedTypes, IStylingProps {}

const BorderWrap = ({ children, color, fitContainer, slim, borderless, ...restProps }: IProps) => {
  const styles = componentStyles({ color, fitContainer, slim, borderless });

  return (
    <Box style={styles.wrapper} {...restProps} data-name="border-wrap">
      <Box style={styles.container}>{children}</Box>
    </Box>
  );
};

export default BorderWrap;
