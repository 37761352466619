import { AuthActionTypes } from 'lib/auth/action-types';
import { getFirebaseMessages } from 'lib/auth/firebase-messages';
import mapFBErrorToValidationError from 'lib/auth/utils/map-fb-error-to-validation-error';
import { toggleSnackbar } from 'lib/bbcommon/actions';
import { getI18n } from 'lib/i18n/getI18n';
import { BridebookError, FirebaseErrorCodes, IDeps } from 'lib/types';

export const resetPassword = (fields: { email: string }) => ({
  type: AuthActionTypes.RESET_PASSWORD,
  payload: { fields },
});

export const resetPasswordSuccess =
  (fields: { email: string }) =>
  ({ dispatch }: IDeps) => {
    dispatch({
      type: 'FIREBASE_RESET_PASSWORD_SUCCESS_ANALYTICS',
      payload: { fields },
    });
    dispatch(
      toggleSnackbar(
        'success',
        getI18n().t('settings:snackbar.passwordReset', { email: fields.email }),
      ),
    );

    return {
      type: AuthActionTypes.RESET_PASSWORD_SUCCESS,
    };
  };

export const resetPasswordError =
  (fields: { email: string | null }, error: BridebookError) =>
  ({ dispatch }: IDeps) => {
    const throwError = getFirebaseMessages()[error.code as FirebaseErrorCodes]
      ? mapFBErrorToValidationError(error.code as FirebaseErrorCodes)
      : error;
    dispatch({
      type: 'FIREBASE_RESET_PASSWORD_ERROR_ANALYTICS',
      payload: { fields, error: throwError },
    });

    return {
      type: AuthActionTypes.RESET_PASSWORD_ERROR,
      payload: throwError,
    };
  };
