import { FelaCSS } from '@bridebook/ui';

interface IStyles {
  title: FelaCSS;
  collaboratorSubtitle: FelaCSS;
  collaboratorFeedbackTitle: FelaCSS;
  collaboratorFeedbackSubtitle: FelaCSS;
}

const styles: IStyles = {
  title: {
    fontPlayfair: 38,
    lineHeight: '120%',
  },
  collaboratorSubtitle: {
    textAlign: 'start',
    fontDefault: 16,
  },
  collaboratorFeedbackTitle: {
    textAlign: 'start',
    fontDefault: 16,
  },
  collaboratorFeedbackSubtitle: {
    textAlign: 'start',
    fontPlayfair: 46,
  },
};

export default styles;
