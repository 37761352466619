import { FelaCSS } from '@bridebook/ui';
import { imgixBaseURL } from 'lib/utils';

interface IStyles {
  wrapper: FelaCSS;
  logo: FelaCSS;
  auth: FelaCSS;
  image(imgPostfix: string): FelaCSS;
  signupContent: FelaCSS;
}

const styles: IStyles = {
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    gridTemplateAreas: `
          "auth"
          `,
    minWidthSmMin: {
      gridTemplateRows: 'auto 1fr',
      gridTemplateAreas: `
            "logo"
            "auth"
            `,
    },
    minWidthSmMax: {
      gridTemplateRows: 'auto',
      gridTemplateColumns: 'minmax(350px, 1fr) 1fr',
      gridTemplateAreas: `
              "logo logo"
              "auth image"
              "auth image"
            `,
    },
  },
  logo: {
    display: 'none',
    minWidthSmMin: {
      display: 'block',
      gridArea: 'logo',
      height: 'max-content',
      marginLeft: 12,
      marginTop: 6,
    },
  },
  auth: {
    gridArea: 'auth',
    width: 'min(100%, 400px)',
    justifySelf: 'center',
    maxWidthSmMin: {
      paddingTop: 6,
      paddingHorizontal: 6,
    },
  },
  image: (imgPostfix) => ({
    display: 'none',
    desktopDesign: {
      gridArea: 'image',
      backgroundImage: `url(${imgixBaseURL}/assets/signup-imgs/background-desktop-qr-${imgPostfix}.jpg)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center 80%',
      height: '100vh',
      backgroundRepeat: 'no-repeat',
      gridRowStart: 1,
      display: 'block',
      marginRight: 12,
    },
    minWidthXLMin: {
      maxWidth: '75%',
      marginRight: 0,
    },
  }),

  signupContent: {
    supportsSafeAreaInset: {
      paddingTop: `env(safe-area-inset-top)`,
      marginBottom: 4,
    },
    display: 'grid',
    gap: 20,
    gridTemplateColumns: 'subgrid',
  },
};

export default styles;
