import { useTranslation } from 'next-i18next';
import React, { memo, useCallback } from 'react';
import { Box, ButtonV2 } from '@bridebook/ui';
import Modal from 'components/bbcommon/modal';
import componentStyles from './confirm-email-popup.style';

interface IProps {
  onClose: () => void;
  onConfirm: () => void;
  email: string;
}

const ConfirmEmailPopup = ({ onClose, onConfirm, email }: IProps) => {
  const { t } = useTranslation('signup');

  const handleConfirm = useCallback(() => {
    onConfirm();
    onClose();
  }, [onClose, onConfirm]);

  const styles = componentStyles();

  return (
    <Modal show={true} id="signup-login-form" maxWidth={550} onClose={onClose}>
      <Box style={styles.modalContainer}>
        <Box style={styles.text}>{t('emailCorrect')}</Box>
        <Box style={styles.userEmail}>{email}</Box>
        <Box style={styles.confirmContainer}>
          <ButtonV2 autoFocus size={'large'} onClick={handleConfirm} dataTest="confirm-email">
            {t('confirmEmail')}
          </ButtonV2>
        </Box>
        <ButtonV2 onClick={onClose} variant={'ghost'}>
          {t('backFromConfirm')}
        </ButtonV2>
      </Box>
    </Modal>
  );
};

export default memo(ConfirmEmailPopup);
