import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  modalContainer: FelaCSS;
  text: FelaCSS;
  userEmail: FelaCSS;
  confirmContainer: FelaCSS;
}

const styles = (): IStyles => ({
  modalContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    marginHorizontal: 4,
    paddingVertical: 2,
    borderRadius: 24,
  },
  text: {
    textAlign: 'center',
    fontPlayfair: 40,
    paddingHorizontal: 4,
    paddingVertical: 5,
  },
  userEmail: {
    textAlign: 'center',
    fontDefault: 16,
    paddingBottom: 4,
  },
  confirmContainer: {
    width: '100%',
    padding: 4,
  },
});

export default styles;
