import { FelaCSS } from '../../fela/flowtypes';

interface IStyles {
  passwordIconContainer: FelaCSS;
}

const styles = (): IStyles => ({
  passwordIconContainer: {
    paddingRight: 4,
    paddingLeft: 1,
    cursor: 'pointer',
  },
});

export default styles;
